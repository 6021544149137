import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useContext, useMemo } from "react";
import TabPanel from "../../../../components/tabpanel";
import { FinanceContext } from "../../../../context/finance-context";
import { useBuyingPowerHistoryByScenario } from "../../../../hooks/scenario.hooks";
import { primaryColor } from "../../../../scss/colors.scss";
import { buildChartDataFromObject } from "../../../../utils/chart-util";
import ScenarioLineChartComponent from "./scenario-line-chart-component";

const useStyles = makeStyles({
  root1: {
    flexGrow: 1,
    boxShadow: "none",
    height: "100%",
    alignSelf: "stretch",
    backgroundColor: "transparent",
    borderBottom: "2px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: primaryColor,
  },
  black: {
    color: "#000000",
  },
  unselect: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  themeFont: {
    fontFamily: ": $font-semi-bold, sans-serif",
  },
  paddingTop: {
    padding: "50px 0px 16px",
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ScenarioChartComponent = ({
  scenarioObject,
  selectedChartTab,
  handleSetSelectedChartTab,
  borrowCapacityListComponent,
}) => {
  const classes = useStyles();

  const { selectedScenarioId } = useContext(FinanceContext);
  const {
    data: buyingPowerHistory,
    isFetching: fetchingBuyingPowerHistory,
    isLoading: loadingBuyingPowerHistory,
  } = useBuyingPowerHistoryByScenario(selectedScenarioId);

  const isGettingBuyingPowerHistory =
    fetchingBuyingPowerHistory || loadingBuyingPowerHistory;

  const handleChange = (event, newValue) => {
    handleSetSelectedChartTab(newValue);
  };

  const maxLoanHistoryChartData = useMemo(() => {
    return buildChartDataFromObject(buyingPowerHistory?.borrowCapacityList);
  }, [buyingPowerHistory?.borrowCapacityList]);

  const maxBuyingPowerChartData = useMemo(() => {
    return buildChartDataFromObject(buyingPowerHistory?.maxBuyingPowerList);
  }, [buyingPowerHistory?.maxBuyingPowerList]);
  return (
    <div className="mb-4">
      <div className="mb-4">
        <AppBar position="static" className={classes.root1}>
          <Tabs
            value={selectedChartTab}
            onChange={handleChange}
            aria-label="simple"
            indicatorColor="secondary"
            textColor="inherit"
            scrollButtons="on"
            variant="scrollable"
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab
              label="Real Buying Power"
              {...a11yProps(0)}
              classes={{
                textColorInherit:
                  selectedChartTab === 0 ? classes.black : classes.unselect,
              }}
            />
            <Tab
              label="Max Loan Amount"
              {...a11yProps(1)}
              classes={{
                textColorInherit:
                  selectedChartTab === 1 ? classes.black : classes.unselect,
              }}
            />
            <Tab
              label="All Lending Options"
              {...a11yProps(2)}
              classes={{
                textColorInherit:
                  selectedChartTab === 2 ? classes.black : classes.unselect,
              }}
            />
          </Tabs>
        </AppBar>
      </div>
      <TabPanel value={selectedChartTab} index={0}>
        <ScenarioLineChartComponent
          data={maxBuyingPowerChartData}
          estimateProperty={scenarioObject?.lvrSetting?.estimateProperty || 0}
          loadingState={isGettingBuyingPowerHistory}
        />
      </TabPanel>
      <TabPanel value={selectedChartTab} index={1}>
        <ScenarioLineChartComponent
          data={maxLoanHistoryChartData}
          estimateProperty={scenarioObject?.lvrSetting?.estimateProperty || 0}
          loadingState={isGettingBuyingPowerHistory}
        />
      </TabPanel>
      <TabPanel value={selectedChartTab} index={2}>
        {borrowCapacityListComponent}
      </TabPanel>
    </div>
  );
};

ScenarioChartComponent.propTypes = {};

export default React.memo(ScenarioChartComponent);
